import revive_payload_client_qiIR80YueA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._glm2ef2oryqxy65fwvh6777rru/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZCEhrYWHNY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._glm2ef2oryqxy65fwvh6777rru/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_eYbC58kVyC from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._glm2ef2oryqxy65fwvh6777rru/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TjDL9xIYfs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.11_sass@1.7_g66qpg3qj22kozsilnmpnnyzbm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_cFWeiHCLcq from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._glm2ef2oryqxy65fwvh6777rru/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_12efj2XLGT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._glm2ef2oryqxy65fwvh6777rru/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bsTUk0AOYR from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._glm2ef2oryqxy65fwvh6777rru/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_EwxyLAAXm1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._glm2ef2oryqxy65fwvh6777rru/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_srDCCPdWeB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._glm2ef2oryqxy65fwvh6777rru/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_1t6fBg7LId from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import maska_ZPLJ7pnO0O from "/opt/buildhome/repo/packages/localdeck-config/packages/localdeck-components/src/plugins/maska.ts";
import matomo_client_1hOWyQFi23 from "/opt/buildhome/repo/src/plugins/matomo.client.ts";
import sentry_client_KAXFuL2wum from "/opt/buildhome/repo/src/plugins/sentry.client.ts";
import toast_client_5GxzM6P4QL from "/opt/buildhome/repo/src/plugins/toast.client.ts";
export default [
  revive_payload_client_qiIR80YueA,
  unhead_ZCEhrYWHNY,
  router_eYbC58kVyC,
  _0_siteConfig_TjDL9xIYfs,
  payload_client_cFWeiHCLcq,
  navigation_repaint_client_12efj2XLGT,
  check_outdated_build_client_bsTUk0AOYR,
  chunk_reload_client_EwxyLAAXm1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_srDCCPdWeB,
  plugin_client_1t6fBg7LId,
  maska_ZPLJ7pnO0O,
  matomo_client_1hOWyQFi23,
  sentry_client_KAXFuL2wum,
  toast_client_5GxzM6P4QL
]