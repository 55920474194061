import { default as index8ytsTBliKPMeta } from "/opt/buildhome/repo/src/pages/[category]/index.vue?macro=true";
import { default as postDAAMAO5gO7Meta } from "/opt/buildhome/repo/src/pages/[category]/post.vue?macro=true";
import { default as indexhGpynX1cdDMeta } from "/opt/buildhome/repo/src/pages/index.vue?macro=true";
import { default as localdeck_45configuratorXjZJfgxjOCMeta } from "/opt/buildhome/repo/src/pages/tools/localdeck-configurator.vue?macro=true";
import { default as tasmota_45calibrationwHM0Al0aCpMeta } from "/opt/buildhome/repo/src/pages/tools/tasmota-calibration.vue?macro=true";
export default [
  {
    name: "category",
    path: "/:category()",
    meta: index8ytsTBliKPMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "category-post",
    path: "/:category/:date/:slug",
    component: () => import("/opt/buildhome/repo/src/pages/[category]/post.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-localdeck-configurator",
    path: "/tools/localdeck-configurator",
    meta: localdeck_45configuratorXjZJfgxjOCMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/tools/localdeck-configurator.vue").then(m => m.default || m)
  },
  {
    name: "tools-tasmota-calibration",
    path: "/tools/tasmota-calibration",
    component: () => import("/opt/buildhome/repo/src/pages/tools/tasmota-calibration.vue").then(m => m.default || m)
  }
]