import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin(({$config, vueApp}) => {
    const router = useRouter();

    Sentry.init({
        app: vueApp,
        dsn: $config.public.sentry_dsn ?? '',
        environment: $config.public.app_env ?? 'development',

        integrations: [
            Sentry.browserTracingIntegration({router}),
            Sentry.replayIntegration(),
        ],
        release: "localbytes/blog@" + ($config.public.git_sha ?? 'dev'),
        // Performance Monitoring
        tracesSampleRate: 0.1,
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
})
