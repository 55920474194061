// @ts-ignore
import VueMatomo from "vue-matomo";
import type {Matomo} from "matomotypes"
import type {RouteLocation, Router} from "vue-router";

declare global {
    // noinspection ES6ConvertVarToLetConst
    var Piwik: Matomo.Tracker;
}

function piwikExists(): Promise<any> {
    // In case of TMS,  we load a first container_XXX.js which triggers aynchronously the loading of the standard Piwik.js
    // this will avoid the error throwed in initMatomo when window.Piwik is undefined
    // if window.Piwik is still undefined when counter reaches 3000ms we reject and go to error


    return new Promise((resolve, reject) => {
        const checkInterval = 50
        const timeout = 3000
        const waitStart = Date.now()

        const interval = setInterval(() => {
            if (window.Piwik) {
                clearInterval(interval)

                return resolve(window.Piwik)
            }

            if (Date.now() >= waitStart + timeout) {
                clearInterval(interval)
                reject();
                throw new Error(`[vue-matomo]: window.Piwik undefined after waiting for ${timeout}ms`)
            }
        }, checkInterval)
    })
}


export function getResolvedHref(router: Router, path: string) {
    // @ts-ignore
    return router?.resolve(path).href
}

function trackMatomoPageView(matomo: Matomo.Tracker, app: any, to: RouteLocation, from?: RouteLocation) {
    let title
    let url
    let referrerUrl

    const router = useRouter();
    if (router) {

        url = getResolvedHref(router, to.fullPath)
        referrerUrl = from && from.fullPath
            ? getResolvedHref(router, from.fullPath)
            : undefined

        if (to?.meta?.analyticsIgnore) {
            app.debug && console.debug('[vue-matomo] Ignoring ' + url)
            return
        }

        title = window.location.host + '/' + (document.title ?? to?.meta?.title ?? url);
        app.debug && console.debug('[vue-matomo] Tracking ' + url)
    }

    if (referrerUrl) {

        matomo.setReferrerUrl(window.location.origin + referrerUrl)
    }
    if (url) {
        matomo.setCustomUrl(window.location.origin + url)
    }

    matomo.trackPageView(title)
}

export default defineNuxtPlugin(async (app) => {
    if (process.env.NODE_ENV !== 'production') return;

    app.vueApp.use(VueMatomo, {
        host: 'https://localbytes.matomo.cloud/',
        siteId: 1,
        cookieDomain: '*.mylocalbytes.com',
        domains: '*.mylocalbytes.com',
        enableHeartBeatTimer: true,
    });


    let matomo: Matomo.Tracker = await piwikExists().then((Piwik) => Piwik.getAsyncTracker());
    // console.log('matomo', matomo);

    useRouter().afterEach((to, from) => {
        trackMatomoPageView(matomo, app, to, from)
        matomo.enableLinkTracking();
    });
});
