import Vue from "vue";
import iconTwitter from "~/assets/socials.old/twitter.svg?component";
import iconInstagram from "~/assets/socials.old/instagram.svg?component";
import iconFacebook from "~/assets/socials.old/facebook.svg?component";
import iconForum from "~/assets/socials.old/chat-bubble.svg?component";
import iconRss from "~/assets/socials.old/rss.svg?component";
import type {MarkdownParsedContent} from "@nuxt/content";


export const socials: Record<string, { icon: Vue.Component | null, title: String, url: String }> = {
    rss: {
        icon: iconRss,
        title: "Subscribe to LocalBytes Blog RSS Feed",
        url: "/rss.xml"
    },
    forum: {
        icon: iconForum,
        title: "Discuss on the LocalBytes Forum",
        url: "https://forum.mylocalbytes.com/"
    },
    twitter: {
        icon: iconTwitter,
        title: "@LocalBytesShop on 𝕏 (Twitter)",
        url: "https://twitter.com/LocalBytesShop"
    },
    instagram: {
        icon: iconInstagram,
        title: "@LocalBytesShop on Instagram",
        url: "https://instagram.com/LocalBytesShop"
    },
    facebook: {
        icon: iconFacebook,
        title: "@LocalBytesShop on Facebook",
        url: "https://facebook.com/LocalBytesShop"
    },
}

export enum ContentType {
    post = 'posts',
    kb = 'kb'
}


export interface Post extends MarkdownParsedContent {
    title: string

    createdAt: string
    updatedAt: string

    headerImg: string
    headerAlt: string
}
